<template>
  <div>
    <b-card-code style="display: flex; flex-direction: column;" title="ثبت بازی جدید">
      <validation-observer ref="loginForm" #default="{ invalid }">
        <!-- title product -->
        <b-row>
          <b-col md="6" class="mb-1">
            <b-form-group label-for="basicInput">
              <h5>عنوان پست</h5>
              <validation-provider #default="{ errors }" name="title" rules="required">
                <b-form-input name="title" id="title" v-model="formData.title" :state="errors.length > 0 ? false : null"
                  placeholder="عنوان پست" />
                <small class="text-danger">{{
                  errors[0] ? "این فیلد الزامی است" : ""
                  }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6" class="mb-1">
            <b-form-group label-for="basicInput">
              <h5>اسلاگ</h5>
              <validation-provider #default="{ errors }" name="slug" rules="required">
                <b-form-input name="slug" id="slug" v-model="formData.slug" :state="errors.length > 0 ? false : null"
                  placeholder="اسلاگ" />
                <small class="text-danger">{{
        errors[0] ? "این فیلد الزامی است" : ""
      }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="p-0 mb-1" md="6" style="padding:0 14px !important;">
            <h5>دسته بندی</h5>
            <div class="d-flex align-items-center justify-content-end">
              <v-select dir="rtl" multiple v-model="formData.categories" :options="categoriesOptions"
                :reduce="(item) => item.value" class="w-100 border" style="
                  border-radius: 5.712px;
                  border-color: #d8d6de !important;
                  
                ">
                <span slot="no-options">موردی یافت نشد.</span>
              </v-select>
            </div>
          </b-col>
          <b-col class="p-0 mb-1" style="padding: 0 14px !important">
            <h5>برچسب ها</h5>
            <div class="d-flex align-items-center justify-content-end">
              <v-select dir="rtl" v-model="formData.tags" :options="tagsOptions" :reduce="(item) => item.value" multiple
                class="w-100 border" style="border-radius: 5.712px; border-color: #d8d6de !important">
                <span slot="no-options">موردی یافت نشد.</span>
              </v-select>
            </div>
          </b-col>
        </b-row>
        <!-- attributes -->
        <b-row v-for="(attr, index) in attributesArray" :key="index">
          <!-- attributes -->
          <b-col class="p-0 mb-1" md="6" style="padding:0 14px !important;">
            <h5>ویژگی ها</h5>
            <div class="d-flex align-items-center justify-content-end">
              <v-select dir="rtl" v-model="attr.attribute_id" :options="attributesOptions"
                :reduce="(item) => item.value" @search="searchAllAttributes"
                @input="attr.attribute_id ? getAllAttributeValues(attr.attribute_id, index) : ''" class="w-100 border"
                style="
                  border-radius: 5.712px;
                  border-color: #d8d6de !important;
                ">
                <span slot="no-options">موردی یافت نشد.</span>
              </v-select>
            </div>
          </b-col>
          <!-- values -->
          <b-col class="p-0 mb-1" md="6" style="padding:0 14px !important;">
            <h5>میزان ویژگی</h5>
            <div class="d-flex align-items-center justify-content-end">
              <v-select dir="ltr" v-model="attr.id" :disabled="!attr.attribute_id" :options="attr.valuesOptions"
                :reduce="(item) => item.value" @search="searchAllAttributeValues($event, attr.attribute_id, index)"
                class="w-100 border" style="
                  border-radius: 5.712px;
                  border-color: #d8d6de !important;">
                <span slot="no-options">موردی یافت نشد.</span>
              </v-select>
            </div>
          </b-col>
          <b-col class="d-md-none" v-if="index + 1 != attributesArray.length">
            <hr>
          </b-col>
          <!-- duplicate -->
          <b-col v-if="index + 1 == attributesArray.length" cols=12 class="text-center mb-1">
            <b-button :class="index > 0 ? 'mr-1' : ''" variant="primary" @click="attributesArrayPlus">
              افزودن ویژگی
            </b-button>
            <b-button class="ml-1" v-if="index > 0" variant="secondary" @click="attributesArrayMinus">
              کاهش ویژگی
            </b-button>
          </b-col>
        </b-row>
        <!-- short explanations -->
        <b-row>
          <b-col cols="12" class="mb-1">
            <h5>توضیحات</h5>
            <validation-provider #default="{ errors }" name="description" rules="required">
              <b-form-textarea name="description" id="textarea-no-resize"
                :state="formData.description.length > 150 ? false : null || errors.length > 0 ? false : null"
                placeholder="توضیحات مختصری برای محصول خود وارد کنید، این توضیحات باید حداکثر 150 کاراکتر باشد" rows="3"
                v-model="formData.description" no-resize />
              <small class="text-danger">{{
                errors[0] ? "این فیلد الزامی است" : ""
                }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- complete explanations -->
        <b-row>
          <b-col cols="12" class="mb-1">
            <h5>توضیحات کامل</h5>
            <tiptap :order="1" v-model="formData.body" />
          </b-col>
        </b-row>
        <!-- minimum system requirements & recommended system requirements -->
        <b-row>
          <b-col cols="12" md="6" class="mb-1">
            <h5>حداقل سیستم مورد نیاز</h5>
            <tiptap :order="2" v-model="formData.minimum_system_requirements" />
          </b-col>
          <b-col cols="12" md="6" class="mb-1">
            <h5>سیستم پیشنهادی</h5>
            <tiptap :order="3" v-model="formData.recommended_system_requirements" />
          </b-col>
        </b-row>
        <!-- installation guide -->
        <b-row>
          <b-col cols="12" class="mb-1">
            <h5>راهنمای نصب</h5>
            <tiptap :order="4" v-model="formData.installation_guide" />
          </b-col>
        </b-row>
        <b-row>
          <!-- meta critic score -->
          <b-col md="4" class="mb-1">
            <b-form-group label-for="metacritic">
              <h5>امتیاز متاکریتیک</h5>
              <b-form-input name="metacritic" id="metacritic" type="number" v-model="formData.metacritic"
                placeholder="عدد وارد کنید." />
            </b-form-group>
          </b-col>
          <!-- release date -->
          <b-col md="4" class="mb-1">
            <b-form-group label-for="release_date">
              <h5>تاریخ انتشار بازی</h5>
              <b-form-input name="release_date" id="release_date" v-model="formData.release_date"
                placeholder="0000/00/00" />
            </b-form-group>
          </b-col>
          <!-- last updated date -->
          <b-col md="4" class="mb-1">
            <b-form-group label-for="last_updated_date">
              <h5>تاریخ آخرین آپدیت</h5>
              <b-form-input name="last_updated_date" id="last_updated_date" v-model="formData.last_updated_date"
                placeholder="0000/00/00" />
            </b-form-group>
          </b-col>
          <b-col class="p-0 mb-1" cols="12" style="padding:0 14px !important;">
            <h5>بازی های مشابه</h5>
            <div class="d-flex align-items-center justify-content-end">
              <v-select dir="rtl" multiple v-model="formData.related_games" :options="relatedGamesOptions"
                :reduce="(item) => item.value" class="w-100 border" style="
                  border-radius: 5.712px;
                  border-color: #d8d6de !important;
                ">
                <span slot="no-options">موردی یافت نشد.</span>
              </v-select>
            </div>
          </b-col>
          <b-col class="p-0 mb-1" cols="12" style="padding:0 14px !important;">
            <b-form-group label-for="gameplay_trailer_url">
              <h5>لینک تریلر</h5>
              <b-form-input name="gameplay_trailer_url" id="gameplay_trailer_url"
                v-model="formData.gameplay_trailer_url" />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- <b-row class="px-1">
          <b-form-checkbox v-model="formData.is_special" value="1">
            <p>فعال سازی لینک بازی در ربات تلگرام</p>
          </b-form-checkbox>
        </b-row> -->
        <!-- uploader -->
        <b-row>
          <b-col cols="12" class="mb-1 d-flex flex-column justify-content-between">
            <div class="">
              <p>تصویر</p>
              <input type="file" class="custom-file-input" id="inputGroupFile01" ref="file"
                placeholder="فایل خود را انتخاب کنید..." accept=".jpeg,.jpg" @input="onChange()"
                style="margin: 36px 12px 0 12px" />
              <label class="custom-file-label" for="inputGroupFile01" style="margin: 36px 12px 0 12px">
                {{ files[0] ? files[0].name : "فایل خود را انتخاب کنید..." }}
              </label>
            </div>
            <div>
              <b-button class="mt-1 mr-1 position-relative" variant="primary" :disabled="!files[0] || thumbnailLoading"
                @click.prevent="thumbnailUpload">
                <b-spinner type="grow" style="width: 1rem; height: 1rem;left: 50%;" class="position-absolute"
                  variant="light" v-if="thumbnailLoading" />
                <span class="text-nowrap">آپلود تصویر بند انگشتی</span>
              </b-button>
              <b-button class="mt-1 position-relative" variant="primary" :disabled="!files[0] || galleryItemLoading"
                @click.prevent="galleryItemUpload">
                <b-spinner type="grow" style="width: 1rem; height: 1rem;left: 50%;" class="position-absolute"
                  variant="light" v-if="galleryItemLoading" />
                <span class="text-nowrap">افزودن به گالری</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mb-1">
            <div class="pt-1 pb-1 overflow">
              <vue-good-table v-if="tempGalleryItemsArray.length > 0" :sort-options="{ enabled: false }"
                :columns="columns" :rows="tempGalleryItemsArray" :rtl="false" class="w-100">
                <div slot="emptystate" class="w-100 text-center">
                  موردی یافت نشد
                </div>
                <template slot="table-row" slot-scope="props" class="mx-auto">
                  <span v-if="props.column.field === 'id'" class="text-nowrap">
                    <span class="text-nowrap">
                      {{ props.row.id ? props.row.id : "-" }}
                    </span>
                  </span>

                  <span v-if="props.column.field === 'name'" class="text-nowrap">
                    <span class="text-nowrap">
                      {{ props.row.name ? props.row.name : "-" }}
                    </span>
                  </span>

                  <!-- Column: Action -->
                  <span v-else-if="props.column.field === 'action'" style="width: 100px !important">
                    <span @click="removeItemFromTempGallery(props.row.id)" style="cursor: pointer;">
                      <feather-icon icon="TrashIcon" />
                    </span>
                  </span>
                </template>
              </vue-good-table>
            </div>
          </b-col>
        </b-row>
        <!-- dynamic download box -->
        <b-row>
          <b-col>
            <div class="w-100">
              <b-row class="px-1">
                <b-form-checkbox v-model="formData.download_box.status" value="0" unchecked-value="1">
                  <p>مخفی کردن باکس دانلود</p>
                </b-form-checkbox>

                <b-col class="p-0 mb-1" cols="12">
                  <b-form-group label-for="hidden_download_box_text">
                    <h5>متن باکس دانلود مخفی شده</h5>
                    <b-form-input name="hidden_download_box_text" id="hidden_download_box_text"
                      v-model="formData.download_box.message" />
                  </b-form-group>
                </b-col>

              </b-row>
              <div v-for="(download_box_item, itemsIndex) in formData.download_box.items" no-body class="">
                <b-col class="mb-1 border rounded  px-1 py-2">
                  <b-form-group :label-for="`downloadBox${itemsIndex}`">
                    <h5>باکس {{ itemsIndex + 1 }}</h5>
                    <validation-provider #default="{ errors }" :name="`downloadBox${itemsIndex}`" rules="required">
                      <b-form-input :name="`downloadBox${itemsIndex}`" :id="`downloadBox${itemsIndex}`"
                        v-model="formData.download_box.items[itemsIndex].title" placeholder="عنوان باکس"
                        :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] ? "این فیلد الزامی است" : "" }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group :label-for="`downloadLinks${itemsIndex}`">
                    <h5>لینک های دانلود</h5>
                    <b-row v-for="(link, linksIndex) in download_box_item.links">
                      <b-col md="4" class="mb-1 sm:mb-0">
                        <validation-provider #default="{ errors }"
                          :name="`downloadBox${itemsIndex}-linkTitle${linksIndex}`" rules="required">
                          <b-form-input v-model="link.title" :name="`downloadBox${itemsIndex}-linkTitle${linksIndex}`"
                            placeholder="عنوان لینک دانلود" :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] ? "این فیلد الزامی است" : "" }}</small>
                        </validation-provider>
                      </b-col>
                      <b-col md="2" class="mb-1 sm:mb-0">
                        <validation-provider #default="{ errors }"
                          :name="`downloadBox${itemsIndex}-linkSize${linksIndex}`" rules="required">
                          <b-form-input v-model="link.size" :name="`downloadBox${itemsIndex}-linkSize${linksIndex}`"
                            placeholder="حجم" :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] ? "این فیلد الزامی است" : "" }}</small>
                        </validation-provider>
                      </b-col>
                      <b-col md="6" class="mb-1 sm:mb-0">
                        <validation-provider #default="{ errors }"
                          :name="`downloadBox${itemsIndex}-linkAddress${linksIndex}`" rules="required">
                          <b-form-input v-model="link.url" :name="`downloadBox${itemsIndex}-linkAddress${linksIndex}`"
                            placeholder="لینک" :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] ? "این فیلد الزامی است" : "" }}</small>
                        </validation-provider>
                      </b-col>
                      <div class="w-100 text-center pt-1" v-if="linksIndex + 1 == download_box_item.links.length">
                        <b-button :class="linksIndex > 0 ? 'mr-1' : ''" variant="primary"
                          @click="downloadBoxLinksArrayPlus(itemsIndex)">
                          افزودن لینک
                        </b-button>
                        <b-button v-if="linksIndex > 0" variant="primary"
                          @click="downloadBoxLinksArrayMinus(itemsIndex)">
                          کاهش لینک
                        </b-button>
                      </div>
                    </b-row>
                  </b-form-group>
                </b-col>
                <div class="w-100 text-center pt-1" v-if="itemsIndex + 1 == formData.download_box.items.length">
                  <b-button variant="primary" class="mr-1" @click="downloadBoxArrayPlus">افزودن
                    باکس</b-button>
                  <b-button variant="primary" @click="downloadBoxArrayMinus">کاهش
                    باکس</b-button>
                </div>
              </div>
              <div class="w-100 text-center pt-1" v-if="!formData.download_box.items.length">
                <b-button variant="primary" @click="downloadBoxArrayPlus">افزودن
                  باکس</b-button>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="px-1">
          <b-form-checkbox v-model="formData.is_special" value="1" unchecked-value="0">
            <p>ویژه</p>
          </b-form-checkbox>
        </b-row>
        <b-row class="px-1">
          <b-form-checkbox v-model="formData.status" value="0" unchecked-value="1">
            <p>ذخیره به عنوان پیش نویس</p>
          </b-form-checkbox>
        </b-row>
        <b-button class="my-2 d-flex justify-content-center align-items-center" variant="primary" @click="createGame()"
          :disabled="isLoading || invalid">
          ثبت
          <b-spinner v-show="isLoading" class="position-absolute" small type="grow" />
        </b-button>
      </validation-observer>
    </b-card-code>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code";
import {
  BFormInput,
  BRow,
  BCol,
  BSpinner,
  BFormGroup,
  BFormTextarea,
  BFormFile,
  BFormSelect,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
// import VueCropper from 'vue-cropperjs';
// import 'cropperjs/dist/cropper.css';
import { VueGoodTable } from "vue-good-table";
import Tiptap from '@/components/Tiptap.vue'
import router from "@/router";

export default {
  components: {
    Tiptap,
    // VueCropper,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    BSpinner,
    BButton,
    BFormSelect,
    BFormFile,
    ToastificationContent,
    vSelect,
    VueGoodTable,
    BFormCheckbox,
  },
  data() {
    return {
      attributesArray: [
        {
          attribute_id: null,
          id: null,
          valuesOptions: [],
        },
      ],
      isLoading: false,
      thumbnailLoading: false,
      galleryItemLoading: false,
      required,
      attribute: null,
      categoryTotal: 0,
      gamesTotal: 0,
      tagsTotal: 0,
      imgSrc: '',
      croppedFile: null,
      files: [],
      file: "",
      tempAttachmentId: null,
      tempGalleryItemId: null,
      tempGalleryItemsArray: [],
      tempAttachmentName: null,
      post_id: null,
      galleryId: null,
      formData: {
        title: "",
        slug: "",
        description: "",
        body: ``,
        release_date: "",
        last_updated_date: "",
        metacritic: "",
        gameplay_trailer_url: "",
        installation_guide: ``,
        minimum_system_requirements: ``,
        recommended_system_requirements: ``,
        thumbnail_id: null,
        attributes: null,
        is_special: 0,
        related_games: [],
        tags: "",
        categories: [],
        download_box: {
          status: 1,
          message: null,
          items: [
            // {
            //   title: '',
            //   order: 0,
            //   links: [
            //     {
            //       title: '',
            //       url: '',
            //       size: '',
            //       order: 0,
            //     },
            //   ],
            // }
          ],
        },
        status: 1,
      },
      columns: [
        {
          label: "شناسه",
          field: "id",
        },
        {
          label: "نام فایل",
          field: "name",
        },
        {
          label: "عملیات",
          field: "action",
        },
      ],
      categoriesOptions: [],
      tagsOptions: [],
      relatedGamesOptions: [],
      attributesOptions: [],
      // cropLoading: true,
    };
  },
  methods: {
    searchAllCategories: debounce(function (e) {
      this.getAllCategories(e);
    }, 500),
    searchAllTags: debounce(function (e) {
      this.getAllTags(e);
    }, 500),
    searchAllGames: debounce(function (e) {
      this.getAllGames(e);
    }, 500),
    searchAllAttributes: debounce(function (e) {
      this.getAllAttributes(e);
    }, 500),
    searchAllAttributeValues: debounce(function (e, id, index) {
      this.getAllAttributeValues(id, index, e);
    }, 500),
    getAllCategories(titleText = "") {
      axios
        .get("/api/v1/admin/categories", { params: { title: titleText } })
        .then((response) => {
          let categoriesTray = response.data.data;
          this.categoryTotal = response.data.meta.total
          categoriesTray.forEach((item) => {
            this.categoriesOptions.push({
              label: item.title,
              value: item.id,
            });
            // including childs
            if (item.childs.length > 0) {
              for (let index = 0; index < item.childs.length; index++) {
                const element = item.childs[index];
                this.categoriesOptions.push({
                  label: element.title,
                  value: element.id,
                });
                // including childs of child
                if (element.childs.length > 0) {
                  for (let index = 0; index < element.childs.length; index++) {
                    const elementTwo = element.childs[index]
                    this.categoriesOptions.push({
                      label: elementTwo.title,
                      value: elementTwo.id,
                    });
                  }
                }
              }
            }
          });
          if (Math.floor(this.categoryTotal / 15) >= 1) {
            let totalPages = Math.floor(this.categoryTotal / 15);
            for (let index = 1; index <= totalPages; index++){
              axios
                .get("/api/v1/admin/categories", { params: { title: titleText, page: index + 1 } }).then(response => {
                  response.data.data.forEach((item) => {
                    this.categoriesOptions.push({
                      label: item.title,
                      value: item.id,
                    });
                    // including childs
                    if (item.childs.length > 0) {
                      for (let index = 0; index < item.childs.length; index++) {
                        const element = item.childs[index];
                        this.categoriesOptions.push({
                          label: element.title,
                          value: element.id,
                        });
                        // including childs of child
                        if (element.childs.length > 0) {
                          for (let index = 0; index < element.childs.length; index++) {
                            const elementTwo = element.childs[index]
                            this.categoriesOptions.push({
                              label: elementTwo.title,
                              value: elementTwo.id,
                            });
                          }
                        }
                      }
                    }
                  })
                })
            }
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی در لیست دسته بندی ها پیش آمده.",
              variant: "danger",
            },
          });
        });
    },
    getAllTags(titleText = "") {
      axios
        .get("/api/v1/admin/tags", { params: { title: titleText } })
        .then((response) => {
          let tagsTray = response.data.data;
          this.tagsTotal = response.data.meta.total
          this.tagsOptions = [];
          tagsTray.forEach((item) => {
            this.tagsOptions.push({
              label: item.title,
              value: item.id,
            });
          });
          if (Math.floor(this.tagsTotal / 15) >= 1) {
            let totalPages = Math.floor(this.tagsTotal / 15);
            for (let index = 1; index <= totalPages; index++) {
              axios
                .get("/api/v1/admin/tags", { params: { title: titleText, page: index + 1 } }).then(response => {
                  response.data.data.forEach((item) => {
                    this.tagsOptions.push({
                      label: item.title,
                      value: item.id,
                    });
                  })
                })
            }
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی در لیست دسته بندی ها پیش آمده.",
              variant: "danger",
            },
          });
        });
    },
    getAllGames(titleText = "") {
      axios
        .get("/api/v1/admin/games", { params: { title: titleText } })
        .then((response) => {
          let productsTray = response.data.data;
          this.relatedGamesOptions = [];
          this.gamesTotal = response.data.meta.total
          productsTray.forEach((item) => {
            this.relatedGamesOptions.push({
              label: item.title,
              value: item.post_id,
            });
          });
          if (Math.floor(this.gamesTotal / 15) >= 1) {
            let totalPages = Math.floor(this.gamesTotal / 15);
            for (let index = 1; index <= totalPages; index++) {
              axios
                .get("/api/v1/admin/games", { params: { title: titleText, page: index + 1 } }).then(response => {
                  response.data.data.forEach((item) => {
                    this.relatedGamesOptions.push({
                      label: item.title,
                      value: item.post_id,
                    });
                  })
                })
            }
           }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی در لیست محصولات پیش آمده.",
              variant: "danger",
            },
          });
        });
    },
    getAllAttributes(titleText = "") {
      axios
        .get("/api/v1/admin/posts/attributes", { params: { label: titleText } })
        .then((response) => {
          let attributesTray = response.data.data;
          this.attributesOptions = [];

          attributesTray.forEach((item) => {
            this.attributesOptions.push({
              label: item.label,
              value: item.id,
            });
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی در لیست محصولات پیش آمده.",
              variant: "danger",
            },
          });
        });
    },
    getAllAttributeValues(id, index = 0, titleText = "") {
      axios
        .get(`/api/v1/admin/posts/attributes/${id}/values`, { params: { value: titleText } })
        .then((response) => {
          let attributeValuesTray = response.data.data;
          this.attributesArray[index].valuesOptions = [];

          attributeValuesTray.forEach((item) => {
            this.attributesArray[index].valuesOptions.push({
              label: item.value,
              value: item.id,
            });
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی در لیست محصولات پیش آمده.",
              variant: "danger",
            },
          });
        });
    },
    // cropImage() {
    //   this.cropLoading = true;
    //   // const { width, height } = this.$refs.cropper.getCropBoxData()
    //   const croppedCanvas = this.$refs.cropper.getCroppedCanvas()
    //   // const adjustedDimensions = this.adjustToAspectRatio(width, height, 16/9);
    //   //croppedCanvas.width = adjustedDimensions.width, croppedCanvas.height = adjustedDimensions.height
    //   const convertToBlob = () => {
    //     return new Promise((resolve) => {
    //       croppedCanvas.toBlob((blob) => {
    //         this.croppedFile = blob
    //         resolve(blob)
    //       }, "image/jpeg");
    //     });
    //   };

    //   convertToBlob().then((blob) => {
    //     this.cropLoading = false;
    //   });
    // },
    // reset() {
    //   this.$refs.cropper.reset();
    // },
    galleryItemUpload() {
      this.galleryItemLoading = true
      this.tempGalleryItemId = null;
      const attachmentFormaData = new FormData();

      if (this.files[0]) {
        let tempFiles = this.files;
        attachmentFormaData.append("file", tempFiles[0], tempFiles[0].name);



        axios
          .post("/api/v1/admin/gallery/items/upload", attachmentFormaData)
          .then((res) => {
            this.galleryItemLoading = false;
            this.tempGalleryItemId = res.data.data.file_id;
            this.tempGalleryItemsArray.push({ id: this.tempGalleryItemId, name: res.data.data.name })
            this.$bvModal
              .msgBoxConfirm('تصویر  با موفقیت آپلود شد', {
                footerClass: "hidden",
                title: 'تصویر آپلود شد',
                size: 'sm',
                hideHeaderClose: false,
                modalClass: "modal-success",
                centered: true,
              })
          })
          .catch((error) => {
            this.galleryItemLoading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "اخطار",
                icon: "XOctagonIcon",
                text: error.response.data.errors && error.response.data.errors.length > 0 ? error.response.data.errors[0].messages[0].message : 'فایل بارگذازی نشد. لطفا دوباره تلاش کنید.',
                variant: "danger",
              },
            });
            this.$refs.file.value = null
            this.files = []
            this.file = ""
          });
      }
    },
    removeItemFromTempGallery(id) {
      if (this.tempGalleryItemsArray.indexOf(this.tempGalleryItemsArray.find(item => item.id == id)) > -1) {
        this.tempGalleryItemsArray.splice(this.tempGalleryItemsArray.indexOf(this.tempGalleryItemsArray.find(item => item.id == id)), 1)
      }
    },
    thumbnailUpload() {
      this.thumbnailLoading = true;
      this.tempAttachmentId = null;
      const attachmentFormaData = new FormData();
      if (this.files[0]) {
        let tempFiles = this.files;
        attachmentFormaData.append("thumbnail", tempFiles[0], tempFiles[0].name);
        // attachmentFormaData.append("thumbnail", this.croppedFile, this.files[0].name);
      }
      // this.tempAttachmentName = attachmentFormaData.get("attachment").name
      axios
        .post("/api/v1/admin/games/thumbnails/upload", attachmentFormaData)
        .then((res) => {
          this.thumbnailLoading = false;
          this.tempAttachmentId = res.data.data.thumbnail_id;
          this.$bvModal
            .msgBoxConfirm('تصویر بندانگشتی با موفقیت آپلود شد', {
              footerClass: "hidden",
              title: 'تصویر بندانگشتی آپلود شد',
              size: 'sm',
              hideHeaderClose: false,
              modalClass: "modal-success",
              centered: true,
            })
        })
        .catch((error) => {
          this.thumbnailLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "اخطار",
              icon: "XOctagonIcon",
              text: error.response.data.errors && error.response.data.errors.length > 0 ? error.response.data.errors[0].messages[0].message : 'فایل بارگذازی نشد. لطفا دوباره تلاش کنید.',
              variant: "danger",
            },
          });
          this.$refs.file.value = null
          this.files = []
          this.file = ""
        });
    },
    onChange() {
      if (this.$refs.file.files[0].size / (1024 * 1024) > 5) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "اخطار",
            icon: "XOctagonIcon",
            text: "حجم فایل انتخاب شده بیش از حد مجاز است. (5MB)",
          },
        });
      } else {
        this.files = [...this.$refs.file.files];
        // if (typeof FileReader === 'function') {
        //   const reader = new FileReader();

        //   reader.onload = (event) => {
        //     this.imgSrc = event.target.result;
        //     this.$refs.cropper.replace(event.target.result);
        //   };

        //   reader.readAsDataURL(this.files[0]);
        // } else {
        //   this.$toast({
        //     component: ToastificationContent,
        //     props: {
        //       title: 'خطا',
        //       icon: 'XOctagonIcon',
        //       text: 'فایل مورد نظر پشتیبانی نمیشود.',
        //     },
        //   })
        // }
      }
    },
    createGame() {
      this.post_id = null
      this.formData.thumbnail_id = this.tempAttachmentId;
      this.formData.attributes = []
      this.attributesArray.forEach((attr) => {
        if (attr.id) {
          this.formData.attributes.push(attr.id)
        }
      })

      if (
        this.formData.title != "" &&
        this.formData.slug != "" &&
        this.formData.description != "" &&
        this.formData.body != "" &&
        this.formData.thumbnail_id != null &&
        this.formData.attributes != [] &&
        this.formData.categories != []
      ) {
        this.isLoading = true;
        axios
          .post("/api/v1/admin/games/create", this.formData)
          .then((res) => {
            this.post_id = res.data.data.post_id
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "بازی اضافه شد",
                icon: "CheckIcon",
                text: "بازی با موفیقت اضافه شد",
                variant: "success",
              },
            });

            this.isLoading = false;

            if (this.tempGalleryItemsArray.length > 0) {
              this.createGallery()
            } else if (res.status == 201) {
              router.push('/games')
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "اخطار",
                icon: "XOctagonIcon",
                text: error.response.data.errors,
                variant: "danger",
              },
            });
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "لطفا فیلد ها را پر کنید",
            variant: "info",
            icon: "XOctagonIcon",
          },
        });
      }
    },
    createGallery() {
      axios
        .post("/api/v1/admin/gallery/create", {
          post_id: this.post_id,
        })
        .then((response) => {
          if (response.status == 201) {
            this.galleryId = response.data.data.id;
            this.$bvModal
              .msgBoxConfirm('گالری  با موفقیت ساخته شد', {
                footerClass: "hidden",
                title: 'گالری ساخته شد',
                size: 'sm',
                hideHeaderClose: false,
                modalClass: "modal-success",
                centered: true,
              })
            this.addItemToGallery();
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "اخطار",
              icon: "XOctagonIcon",
              text: "مشکلی پیش آمده!",
              variant: "danger",
            },
          });
        });
    },
    addItemToGallery() {
      this.tempGalleryItemsArray.forEach((item, index) => {
        axios
          .post("/api/v1/admin/gallery/items/add", {
            gallery_id: this.galleryId,
            file_id: item.id,
          })
          .then((res) => {
            if (index == this.tempGalleryItemsArray.length - 2) {
              this.$bvModal
                .msgBoxConfirm('تصویر گالری با موفقیت آپلود شد', {
                  footerClass: "hidden",
                  title: 'تصویر گالری آپلود شد',
                  size: 'sm',
                  hideHeaderClose: false,
                  modalClass: "modal-success",
                  centered: true,
                })
            }

            if (index == this.tempGalleryItemsArray.length - 1) {
              router.push('/games')
            }
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "اخطار",
                icon: "XOctagonIcon",
                text: "فایل بارگذازی نشد. لطفا دوباره تلاش کنید.",
                variant: "danger",
              },
            });
          });
      })
    },
    downloadBoxArrayPlus() {
      this.formData.download_box.items.push({
        title: '',
        order: 0,
        links: [{
          title: '',
          url: '',
          size: '',
          order: 0,
        }],
      })
    },
    downloadBoxArrayMinus() {
      this.formData.download_box.items.pop()
    },
    downloadBoxLinksArrayPlus(index) {
      this.formData.download_box.items[index].links.push({
        title: '',
        url: '',
        size: '',
        order: 0,
      })
    },
    downloadBoxLinksArrayMinus(index) {
      this.formData.download_box.items[index].links.pop();
    },
    attributesArrayPlus() {
      this.attributesArray.push({
        attribute_id: null,
        id: null,
        valuesOptions: [],
      })
    },
    attributesArrayMinus() {
      this.attributesArray.pop()
    },
  },
  mounted() {
    this.getAllCategories()
    this.getAllGames()
    this.getAllAttributes()
    this.getAllTags()
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";

// .crop-placeholder {
//   height: 291px;
//   background: #ccc;
// }

// .cropped-image {
//   min-height: 200px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .cropped-image img {
//   max-width: 100%;
//   // max-height: 400px;
// }

// .cropper-crop-box {
//   display: flex;
// }

// .cropper-container {
//   direction: ltr !important;
//   // max-height: 291px !important;
//   overflow: hidden;
// }

// .cropper-bg {
//   direction: ltr !important;
// }

// [dir=rtl] .cropper-container {
//   direction: ltr !important;
// }</style>